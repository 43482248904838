import { useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import CommonLogo from './CommonLogo';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn, H4, P } from '../../../../AbstractElements';
import { CreateYourAccount } from '../../../../utils/Constant';
import axios from 'axios';
import { CommonFormPropsType } from '../../../../Types/OtherPages/OtherPages';
import ENV from '../../../../env';

const CommonRegisterForm = ({ alignLogo }: CommonFormPropsType) => {
    const [showPassWord, setShowPassWord] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate(); // Tambahkan useNavigate

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');

        try {
            const response = await axios.post(
                `${ENV.API_URL}/auth/register`,
                formData,
                { timeout: 10000 }
            );

            const { status, message } = response.data;

            if (status) {
                setSuccess(message || 'Registration successful!');
                setFormData({
                    username: '',
                    email: '',
                    password: '',
                });

                // Tampilkan pesan sukses selama 2 detik, lalu navigasikan ke halaman login
                setTimeout(() => {
                    navigate(`${ENV.PUBLIC_URL}/login`, { replace: true });
                }, 2000);
            } else {
                setError(message || 'An error occurred.');
            }
        } catch (err: any) {
            if (err.code === 'ECONNABORTED') {
                setError('Request timeout. Please try again.');
            } else if (err.response) {
                const { message } = err.response.data;
                setError(message || 'Something went wrong.');
            } else {
                setError('Failed to register. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-card login-dark">
            <div>
                <div>
                    <CommonLogo alignLogo={alignLogo} />
                </div>
                <div className="login-main">
                    <Form className="theme-form" onSubmit={handleSubmit}>
                        <H4>{CreateYourAccount}</H4>
                        <P>{"Enter your personal details to create account"}</P>
                        <FormGroup>
                            <Label className="col-form-label pt-0">{"Your Username"}</Label>
                            <Row className="g-2">
                                <Col xs="12">
                                    <Input
                                        type="text"
                                        name="username"
                                        required
                                        value={formData.username}
                                        onChange={handleChange}
                                        placeholder={"Enter username"}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Label className="col-form-label pt-0">{"Your Email"}</Label>
                            <Row className="g-2">
                                <Col xs="12">
                                    <Input
                                        type="email"
                                        name="email"
                                        required
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder={"Enter email"}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Label className="col-form-label pt-0">{"Your Password"}</Label>
                            <Row className="g-2">
                                <Col xs="12">
                                    <Input
                                        type={showPassWord ? 'text' : 'password'}
                                        name="password"
                                        required
                                        value={formData.password}
                                        onChange={handleChange}
                                        placeholder={"Enter password"}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <Btn
                            type="submit"
                            color="primary"
                            disabled={loading}
                            className="w-100"
                        >
                            {loading ? 'Loading...' : 'Create Account'}
                        </Btn>
                        {error && <P className="text-danger mt-3">{error}</P>}
                        {success && <P className="text-success mt-3">{success}</P>}
                        <P className="mt-3 mb-0">
                            Already have an account?{' '}
                            <Link to={`${ENV.PUBLIC_URL}/login`} className="ms-2">
                                Sign in
                            </Link>
                        </P>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default CommonRegisterForm;
