import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { dynamicImage } from "../../../../Service";
import {
  CreateAccount,
  DoNotAccount,
  EmailAddress,
  ForgotPassword,
  Href,
  Password,
  RememberPassword,
  SignIn,
  SignInAccount,
} from "../../../../utils/Constant";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Btn, H3, Image, P } from "../../../../AbstractElements";
import ENV from "../../../../env";

const Login = () => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const SimpleLoginHandle = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${ENV.API_URL}/auth/login`, {
        email,
        password,
      });

      if (response.status === 200 && response.data.status) {
        // Simpan semua data respons login
        const responseData = response.data;
        console.log(responseData);
        if (rememberMe) {
          // Simpan ke localStorage
          Object.keys(responseData).forEach((key) => {
            localStorage.setItem(key, JSON.stringify(responseData[key]));
          });
        } else {
          // Simpan ke sessionStorage
          Object.keys(responseData).forEach((key) => {
            sessionStorage.setItem(key, JSON.stringify(responseData[key]));
          });
        }

        toast.success("Login successful!");
        navigate(`/dashboard/app/`);
      } else {
        toast.error(response.data.error || "Invalid email or password");
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card login-dark">
            <div>
              <div>
                <Link className="logo text-center" to={`${ENV.PUBLIC_URL}${Href}`}>
                  <Image
                    className="img-fluid for-light"
                    src={dynamicImage("logo/logo.png")}
                    alt="loginpage"
                  />
                  <Image
                    className="img-fluid for-dark"
                    src={dynamicImage("logo/logo_dark.png")}
                    alt="loginpage"
                  />
                  DATA SENTIMEN
                </Link>
              </div>
              <div className="login-main">
                <Form className="theme-form" onSubmit={SimpleLoginHandle}>
                  <H3>{SignInAccount}</H3>
                  <P>{"Enter your email & password to login APP"}</P>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input
                      type="email"
                      required
                      placeholder="Test@gmail.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{Password}</Label>
                    <div className="form-input position-relative">
                      <Input
                        type={show ? "text" : "password"}
                        placeholder="*********"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div className="show-hide" onClick={() => setShow(!show)}>
                        <span className="show"> </span>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-0 form-sub-title">
                    <div className="checkbox p-0">
                      <input
                        type="checkbox"
                        id="rememberMe"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                        required
                      />
                      <Label htmlFor="rememberMe">
                        {RememberPassword} <span className="text-danger">*</span>
                      </Label>
                    </div>
                    {!rememberMe && (
                      <small className="text-danger">
                        You must agree to remember password
                      </small>
                    )}
                  </FormGroup>
                  <Btn color="primary" type="submit" block disabled={loading}>
                    {loading ? "Logging In..." : SignIn}
                  </Btn>
                  <Row className="mt-4">
                    <Col xs="6"></Col>
                    <Col xs="6" className="text-end">
                      <Link to={`${ENV.PUBLIC_URL}/register`}>{DoNotAccount}</Link>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
