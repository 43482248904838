import { useState, useEffect } from "react";
import { Image, LI, P } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import ProfileBox from "./ProfileBox";

const UserProfile = () => {
  const [userData, setUserData] = useState({
    username: "",
    role: "",
  });

  useEffect(() => {
    // Ambil data dari localStorage
    const username = localStorage.getItem("username") || "Guest";
    const role = localStorage.getItem("role") || "User";

    // Set data ke state
    setUserData({ username, role });
  }, []);

  return (
    <LI className="profile-nav onhover-dropdown px-0 py-0">
      <div className="d-flex profile-media align-items-center">
        <Image className="img-30" src={dynamicImage("dashboard/profile.png")} alt="user" />
        <div className="flex-grow-1">
          <span>{userData.username}</span>
          <P className="mb-0 font-outfit">{userData.role}
            <i className="fa fa-angle-down" />
          </P>
        </div>
      </div>
      <ProfileBox />
    </LI>
  );
};

export default UserProfile;
