import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../ReduxToolkit/Hooks';
import { Image, SVG } from '../../AbstractElements';
import { handleResponsiveToggle, setToggleSidebar } from '../../ReduxToolkit/Reducers/LayoutSlice';
import { dynamicImage } from '../../Service';

const LogoWrapper = () => {
    const dispatch = useAppDispatch();
    const { toggleSidebar } = useAppSelector((state) => state.layout);
    const { sidebarIconType } = useAppSelector((state) => state.themeCustomizer);

    const logoStyle = {
        width: '97px', // Mengatur lebar gambar
        height: 'auto' // Menjaga proporsi gambar
    };

    return (
        <>
            <div className="logo-wrapper">
                <Link to={`/dashboard/default`}>
                    <Image className="img-fluid" src='/images/kemanker.png' alt="logo" style={logoStyle} />
                </Link>
                <div className="back-btn" onClick={() => dispatch(handleResponsiveToggle())}>
                    <i className="fa fa-angle-left"></i>
                </div>
                <div className="toggle-sidebar">
                    <SVG 
                        className={`${sidebarIconType}-icon sidebar-toggle status_toggle middle`} 
                        iconId={`${sidebarIconType === "fill" ? "fill-" : ""}toggle-icon`} 
                        onClick={() => dispatch(setToggleSidebar(!toggleSidebar))} 
                    />
                </div>
            </div>
            <div className="logo-icon-wrapper">
                <Link to={`${process.env.PUBLIC_URL}/dashboard/default`}>
                    <Image className="img-fluid" src={dynamicImage("logo/logo-icon.png")} alt="logo" style={logoStyle} />
                </Link>
            </div>
        </>
    );
};

export default LogoWrapper;
