const isTokenExpired = (): boolean => {
    const exp = localStorage.getItem("exp"); // Mendapatkan waktu kedaluwarsa dari localStorage
    if (exp) {
      const currentTime = Date.now() / 1000; // Konversi ke detik
      return parseInt(exp, 10) < currentTime; // True jika token sudah kedaluwarsa
    }
    return true; // Jika tidak ada `exp`, anggap token kedaluwarsa
  };
  
  export default isTokenExpired;
  