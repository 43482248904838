import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Spinner, Modal, ModalHeader, ModalBody } from 'reactstrap';
import './KeywordDomains.css';
import ENV from "./../../../../../env";
const live_url = ENV.API_URL;

interface Domain {
  domain_id: number;
  domain_name: string;
  link_id: number;
  url: string;
  icon_url?: string;
  domain_created_at: string;
  sentiment_category: 'positive' | 'negative' | 'neutral';
  sentiment_value: string;
  kesimpulan: string;
  konten_terkait: string;
}

interface LinkDetail {
  domain_name: string;
  url: string;
  sentiment_category: string;
  sentiment_value: string;
  kesimpulan: string;
  konten_terkait: string;
  metode: string;
}

const ITEMS_PER_PAGE = 6;

const DomainList: React.FC = () => {
  const [domains, setDomains] = useState<Domain[]>([]);
  const [filteredDomains, setFilteredDomains] = useState<Domain[]>([]);
  const [visibleCount, setVisibleCount] = useState<number>(ITEMS_PER_PAGE);
  const [linkDetails, setLinkDetails] = useState<LinkDetail | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<string>('All');

  const clientId = localStorage.getItem('client_id');

  useEffect(() => {
    if (clientId) {
      fetchDomains(clientId);
    }
  }, [clientId]);

  const fetchDomains = async (clientId: string) => {
    try {
      setLoading(true);
      const response = await fetch(`${live_url}/fetchlinks/get-links?client_id=${clientId}`);
      const data = await response.json();

      if (data.error) {
        console.error('Error fetching domains:', data.error);
        return;
      }

      setDomains(data.data);
      setFilteredDomains(data.data);
    } catch (error) {
      console.error('Error fetching domains:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchLinkDetails = async (linkId: number) => {
    try {
        setLoading(true);
        const response = await fetch(`${live_url}/fetchlinks/domains/details/${linkId}`);
        const data = await response.json();

        if (data.error) {
            console.error('Error fetching link details:', data.error);
            return;
        }

        // Ambil data pada indeks [0]
        if (data.data && data.data.length > 0) {
            setLinkDetails(data.data[0]);
        } else {
            console.error('No data found for the provided link ID.');
        }
        setModalOpen(true);
    } catch (error) {
        console.error('Error fetching link details:', error);
    } finally {
        setLoading(false);
    }
};


  const toggleModal = () => setModalOpen(!modalOpen);

  const handleFilterChange = (category: string) => {
    setSelectedFilter(category);
    setVisibleCount(ITEMS_PER_PAGE);
    if (category === 'All') {
      setFilteredDomains(domains);
    } else {
      const filtered = domains.filter(
        (domain) => domain.sentiment_category === category
      );
      setFilteredDomains(filtered);
    }
  };

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + ITEMS_PER_PAGE);
  };

  const truncateText = (text: string, maxLength: number): string => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  const visibleDomains = filteredDomains.slice(0, visibleCount);

  return (
    <Container className="mt-4">
      <h1 className="text-center mb-4">Berikut Adalah list Domain</h1>
      {loading && (
        <div className="text-center">
          <Spinner color="primary" />
          <p>Loading content, please wait...</p>
        </div>
      )}

      {!loading && (
        <>
          <div className="mb-3 d-flex justify-content-start align-items-center">
            <button
              className={`btn me-2 px-4 ${selectedFilter === 'All' ? 'btn-primary' : 'btn-outline-primary'}`}
              style={{
                borderRadius: '20px',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              }}
              onClick={() => handleFilterChange('All')}
            >
              All
            </button>
            <button
              className={`btn me-2 px-4 ${selectedFilter === 'positive' ? 'btn-success' : 'btn-outline-success'}`}
              style={{
                borderRadius: '20px',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              }}
              onClick={() => handleFilterChange('positive')}
            >
              Positive
            </button>
            <button
              className={`btn me-2 px-4 ${selectedFilter === 'negative' ? 'btn-danger' : 'btn-outline-danger'}`}
              style={{
                borderRadius: '20px',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              }}
              onClick={() => handleFilterChange('negative')}
            >
              Negative
            </button>
            <button
              className={`btn me-2 px-4 ${selectedFilter === 'neutral' ? 'btn-primary' : 'btn-outline-primary'}`}
              style={{
                borderRadius: '20px',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              }}
              onClick={() => handleFilterChange('neutral')}
            >
              Neutral
            </button>
          </div>

          {visibleDomains.length > 0 ? (
            <Row>
              {visibleDomains.map((domain) => (
                <Col md={6} lg={4} sm={12} className="mb-4" key={domain.domain_id}>
                  <div
                    className="card border-0 shadow-sm h-100"
                    style={{
                      backgroundColor: domain.sentiment_category === 'positive' ? '#e9f7ef' : domain.sentiment_category === 'negative' ? '#fce4e4' : '#e8f4fc',
                      borderRadius: '10px',
                    }}
                  >
                    {domain.icon_url ? (
                    <img
                      src={domain.icon_url}
                      alt="Domain Icon"
                      className="card-img-top"
                      style={{
                        height: '200px',
                        objectFit: 'cover',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                      }}
                    />
                  ) : (
                    <img
                      src="/images/error.png" // Path ke gambar default
                      alt="Default Domain Icon"
                      className="card-img-top"
                      style={{
                        height: '200px',
                        objectFit: 'cover',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                      }}
                    />
                  )}

                    <div className="card-body d-flex flex-column justify-content-between">
                      <div>
                        <div className="d-flex align-items-center mb-3">
                          <img
                            src={`https://www.google.com/s2/favicons?domain=${domain.domain_name}`}
                            alt="Web Icon"
                            className="me-2"
                            style={{ width: '30px', height: '30px', borderRadius: '5px' }}
                          />
                          <h5 className="card-title text-primary mb-0" title={domain.domain_name || 'Unknown Domain'}>
                            {truncateText(domain.domain_name, 20)}
                          </h5>
                        </div>
                        <p className="card-text">
                          <strong>Link:</strong>{' '}
                          <a href={domain.url} target="_blank" rel="noopener noreferrer">
                            Visit Link
                          </a>
                        </p>
                        <p className="card-text">
                          <strong>Sentimen:</strong>{' '}
                          <span className={`badge text-white ${domain.sentiment_category === 'positive' ? 'bg-success' : domain.sentiment_category === 'negative' ? 'bg-danger' : 'bg-primary'}`}>
                            {domain.sentiment_category || 'Neutral'}
                          </span>
                          <br />
                          <strong>Nilai Sentimen:</strong> {domain.sentiment_value || '0/100'} <br />
                        </p>
                      </div>
                      <div className="text-end">
                        <button
                          className="btn btn-outline-primary btn-sm px-4"
                          style={{
                            borderRadius: '20px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                          }}
                          onClick={() => fetchLinkDetails(domain.link_id)}
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            <div className="text-center mt-5">
              <h4 className="text-muted">Data tidak tersedia</h4>
              <p>Silakan pilih kategori lain atau coba lagi nanti.</p>
            </div>
          )}

          {filteredDomains.length > visibleCount && (
            <div className="text-center mt-4 mb-4">
              <button className="btn btn-primary btn-sm px-5" onClick={handleLoadMore}>
                Load More
              </button>
            </div>
          )}
        </>
      )}

      <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Detailed Link Information</ModalHeader>
        <ModalBody>
          {loading ? (
            <Spinner color="primary" />
          ) : (
            linkDetails && (
              <div>
                <p>
                  <strong>Domain Name:</strong> {linkDetails.domain_name || 'N/A'}
                </p>
                

                <p>
                  <strong>Nilai Sentimen:</strong> {linkDetails.sentiment_value || 'N/A'}
                </p>
                <p>
                  <strong>Sentimen:</strong> {linkDetails.sentiment_category || 'N/A'}
                </p>
                

                <p>
                  <strong>Kesimpulan:</strong> {linkDetails.kesimpulan || 'N/A'}
                </p>
                <p>
                  <strong>Metode:</strong> {linkDetails.metode || 'N/A'}
                </p>
                <p>
                  <strong>Konten Terkait:</strong> {linkDetails.konten_terkait || 'N/A'}
                </p>
              </div>
            )
          )}
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default DomainList;
