import DetailDomains from '../../../../Componant/Dashboard/Summary/DetailDomains'

const ListDomains = () => {
  return (
    <div className="page-body">
      <DetailDomains />
    </div>
  )
}

export default ListDomains