import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import axios from "axios";
import ENV from './../../../../env';
import { FaTwitter, FaTiktok, FaInstagram, FaYoutube, FaGlobe } from "react-icons/fa"; // Icons for platforms

// Define the structure of the card data
interface CardData {
  platform: string;
  sentiment_value: string; // Only sentiment_value will be displayed
  image: string; // URL for platform image
  total_links?: number; // Total number of links for each platform (optional)
}

// Dummy data for other platforms with images from /public folder
const dummyCardData: CardData[] = [
  { platform: "Twitter", sentiment_value: "0/100", image: "/images/twitter.png", total_links: 0 },
  { platform: "TikTok", sentiment_value: "0/100", image: "/images/tiktok.png", total_links: 0 },
  { platform: "Instagram", sentiment_value: "0/100", image: "/images/instagram.png", total_links: 0 },
  { platform: "YouTube", sentiment_value: "0/100", image: "/images/youtube.png", total_links: 0 },
];

const TotalSummaryPlatform: React.FC = () => {
  const [allData, setAllData] = useState<CardData[]>(dummyCardData); // State for all platform data
  const [loading, setLoading] = useState<boolean>(true); // Loading state

  const token = localStorage.getItem('token'); // Retrieve token from local storage
  const clientId = localStorage.getItem('client_id'); // Retrieve client_id from local storage

  useEffect(() => {
    if (token && clientId) {
      fetchWebData(clientId, token); // Fetch Web-specific data and merge
      fetchLinksCountData(clientId, token); // Fetch total count for Web platform
    }
  }, [token, clientId]);

  // Fetch data specifically for Web platform (Sentiment Value)
  const fetchWebData = async (clientId: string, token: string) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${ENV.API_URL}/keywords/get/keyword-summaries/${clientId}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      // Extract the first item from the response array for Web data
      const data = response.data.data[0]; // Assuming Web data is the first item
      const webData: CardData = {
        platform: "Web",
        sentiment_value: data.sentiment_value, // Sentiment Value for Web
        image: "/images/web_logo.png", // Custom image for Web from /public/images
        total_links: 0, // Placeholder for total_links, will be updated by fetchLinksCountData
      };

      // Merge Web data with other platforms, avoiding duplicate Web entry
      setAllData((prevData) => {
        const existingWeb = prevData.find((item) => item.platform === "Web");
        if (!existingWeb) {
          return [webData, ...prevData]; // Add Web data only if it's not already there
        }
        return prevData;
      });
    } catch (error) {
      console.error("Error fetching Web data:", error);

      // Fallback for Web if API fails
      const webData: CardData = {
        platform: "Web",
        sentiment_value: "No data", // Sentiment value fallback
        image: "/images/web_logo.png", // Fallback Web image from /public/images
        total_links: 0, // Fallback for total_links
      };
      setAllData((prevData) => [webData, ...prevData]);
    } finally {
      setLoading(false);
    }
  };

  // Fetch total links count for Web platform
  const fetchLinksCountData = async (clientId: string, token: string) => {
    try {
      const response = await axios.get(
        `${ENV.API_URL}/fetchlinks/count-valid-urls/${clientId}`, // New endpoint to get valid URL count
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      const validUrlCount = response.data.valid_url_count; // Assuming the response contains the valid URL count for Web
      updateWebDataLinks(validUrlCount); // Set the total links count for Web platform
    } catch (error) {
      console.error("Error fetching total links for Web:", error);
    }
  };

  // Function to update total_links for Web
  const updateWebDataLinks = (validUrlCount: number) => {
    setAllData((prevData) =>
      prevData.map((item) =>
        item.platform === "Web" ? { ...item, total_links: validUrlCount } : item
      )
    );
  };

  return (
    <div className="container mt-4 mb-5">
      <h3 className="mb-4 text-center">Platform Sentiment Summary</h3>
      {loading ? (
        <p>Loading platform data...</p>
      ) : (
        <Row xs={1} sm={2} md={3} lg={5} className="g-4 mb-4">
          {allData.map((card, index) => (
            <Col key={index}>
              <Card className="text-center shadow-sm h-100">
                <Card.Img
                  variant="top"
                  src={card.image}
                  alt={`${card.platform} logo`}
                  className="p-3"
                  style={{ height: "100px", objectFit: "contain" }} // Smaller image size
                />
                <Card.Body>
                  <Card.Title className="text-uppercase">{card.platform}</Card.Title>
                  <Card.Text>
                    <strong>Sentiment Value:</strong> {card.sentiment_value}
                  </Card.Text>
                  {/* Add total_links below sentiment_value */}
                  <Card.Text>
                    <strong>Total Links:</strong> {card.total_links || 0} {/* Display total links */}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      
      )}
    </div>
  );
};

export default TotalSummaryPlatform;
