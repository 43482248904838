import { Container, Row } from "reactstrap";
import TotalSummary from "./TotalSummary/Total";
import DomainList from "./TotalSummary/Domains/DomainList";
import TotalSummaryPlatform from "./TotalSummary/TotalSummaryPlatform";

import KeywordSentiments from "./TotalSummary/KeywordSentiments";


const ContainerEducation = () => {
  return (
    <Container fluid className="dashboard-4">
      <Row>
        <TotalSummaryPlatform />
        <KeywordSentiments />
        <DomainList />
        
      </Row>
    </Container>
  );
};

export default ContainerEducation;
