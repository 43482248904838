import { Container, Row } from "reactstrap";
import TotalSummary from "./TotalSummary/Total";

import DetailDomains from "./TotalSummary/Domains/DetailDomains";

const ContainerEducation = () => {
  return (
    <Container fluid className="dashboard-4">
      <Row>
   
        <DetailDomains /> 
        
      </Row>
    </Container>
  );
};

export default ContainerEducation;
