import ContainerSummary from '../../../../Componant/Dashboard/Summary/Domains'

const ListDomains = () => {
  return (
    <div className="page-body">
      <ContainerSummary />
    </div>
  )
}

export default ListDomains