import RegisterContainer from '../../../../Componant/OtherPages/Authentication/LoginAppHome/LoginApp'

const LoginApp = () => {
  return (
    <div className='page-body'>
      <RegisterContainer />
    </div>
  )
}

export default LoginApp