import { Navigate } from "react-router-dom";
import Login from "../Pages/OtherPages/Authentication/Login/LoginApp";
import Register from "../Pages/OtherPages/Authentication/Register/RegisterApp";
import isTokenExpired from "../utils/isTokenExpired";

// Helper function to check if the token is null or expired
const shouldRedirectToLogin = () => {
  const token = localStorage.getItem("token"); // Adjust key based on where token is stored
  return !token || isTokenExpired();
};

export const authRoutes = [
  {
    path: `${process.env.PUBLIC_URL}/register`,
    Component: shouldRedirectToLogin() ? <Navigate to={`${process.env.PUBLIC_URL}/login`} /> : <Register />,
  },
  {
    path: `${process.env.PUBLIC_URL}/login`,
    Component: shouldRedirectToLogin() ? <Login /> : <Navigate to={`${process.env.PUBLIC_URL}/dashboard/app`} />,
  },
];
