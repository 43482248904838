import { Container, Row } from "reactstrap";
import TotalSummary from "./TotalSummary/Total";

import KeywordDomains from "./TotalSummary/Domains/ListDomains";

// import AssignmentsTable from "./AssignmentsTable/AssignmentsTable";
// import LiveMeeting from "./LiveMeeting/LiveMeeting";
// import ActivelyHours from "./ActivelyHours/ActivelyHours";
// import EnrolledClasses from "./EnrolledClasses/EnrolledClasses";
// import FeaturedCourses from "./FeaturedCourses/FeaturedCourses";
// import MonthlyAttendance from "./MonthlyAttendance/MonthlyAttendance";
// import Schedule from "./Schedule/Schedule";
// import SearchInput from "./SearchHome/Search";

const ContainerEducation = () => {
  return (
    <Container fluid className="dashboard-4">
      <Row>
   
        <KeywordDomains /> 
        {/* <AssignmentsTable />
        <LiveMeeting /> */}
                

        {/* <ActivelyHours />
        <EnrolledClasses />
        <FeaturedCourses />
        <MonthlyAttendance />
        <Schedule /> */} 
      </Row>
    </Container>
  );
};

export default ContainerEducation;
