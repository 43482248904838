import RegisterContainer from '../../../../Componant/OtherPages/Authentication/Register/Register'

const RegisterSimple = () => {
  return (
    <div className='page-body'>
      <RegisterContainer />
    </div>
  )
}

export default RegisterSimple