import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, Table, Spinner } from 'reactstrap';

const API_URL = "http://127.0.0.1:5000";

interface Keyword {
  id: number;
  keyword: string;
}

interface Domain {
  id: number;
  domain: string;
}

const KeywordDomains: React.FC = () => {
  const [keywords, setKeywords] = useState<Keyword[] | null>(null);
  const [domains, setDomains] = useState<Domain[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const userId = localStorage.getItem('user_id');
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (userId && token) {
      fetchKeywordsAndDomains(userId, token);
    }
  }, [userId, token]);

  const fetchKeywordsAndDomains = async (userId: string, token: string) => {
    try {
      setLoading(true);

      // Fetch keywords
      const keywordsResponse = await axios.get(
        `${API_URL}/keywords/get-keywords/${userId}`,
        { headers: { Authorization: token } }
      );
      const fetchedKeywords: Keyword[] = keywordsResponse.data.data;
      setKeywords(fetchedKeywords);

      if (fetchedKeywords.length > 0) {
        const keywordId = fetchedKeywords[0].id;

        // Fetch domains for the keyword
        const domainsResponse = await axios.get(
          `${API_URL}/keywords/link-domain/${keywordId}`,
          { headers: { Authorization: token } }
        );
        setDomains(domainsResponse.data.domains); // Adjust according to API response
      }
    } catch (error) {
      console.error('Error fetching keywords and domains:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDomainClick = (domainId: number) => {
    navigate(`/dashboard/app/list-domain/detail/${domainId}`);
  };

  return (
    <Container className="mt-4">
      <h1 className="text-center mb-4">Domains List</h1>
      {loading && (
        <div className="text-center">
          <Spinner color="primary" />
          <p>Loading domains...</p>
        </div>
      )}
      {!loading && domains && domains.length > 0 && (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Domain Name</th>
            </tr>
          </thead>
          <tbody>
            {domains.map((domain, index) => (
              <tr key={domain.id}>
                <td>{index + 1}</td>
                <td>
                  <span
                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    onClick={() => handleDomainClick(domain.id)}
                  >
                    {domain.domain}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {!loading && (!domains || domains.length === 0) && (
        <p className="text-center">No domains found for the selected keyword.</p>
      )}
    </Container>
  );
};

export default KeywordDomains;
