import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import isTokenExpired from "../utils/isTokenExpired";

const AutoLogout: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const exp = localStorage.getItem("exp");
    if (exp) {
      const expirationTime = parseInt(exp, 10) * 1000; // Waktu kedaluwarsa dalam milidetik
      const currentTime = Date.now();

      if (expirationTime <= currentTime) {
        // Token sudah kedaluwarsa, lakukan logout
        handleLogout();
      } else {
        // Atur timer untuk logout otomatis saat token kedaluwarsa
        const timer = setTimeout(() => {
          handleLogout();
        }, expirationTime - currentTime);

        return () => clearTimeout(timer); // Bersihkan timer saat komponen unmount
      }
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate(`${process.env.PUBLIC_URL}/login`);
  };

  return null;
};

export default AutoLogout;
