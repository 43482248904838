import { Navigate, Outlet } from "react-router-dom";
import isTokenExpired from "../utils/isTokenExpired";

const PrivateRoute: React.FC = () => {
  const login = JSON.parse(localStorage.getItem("login") || "false");

  // Periksa apakah pengguna sudah login dan token masih valid
  if (!login || isTokenExpired()) {
    // Hapus data sesi jika token sudah kedaluwarsa
    localStorage.clear();
    sessionStorage.clear();
    return <Navigate to={`${process.env.PUBLIC_URL}/login`} />;
  }

  // Render rute yang dilindungi jika login valid dan token masih aktif
  return <Outlet />;
};

export default PrivateRoute;
