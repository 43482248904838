import SearchInput from "../Pages/Application/SearchInput/SearchResult";
import DashboardHome from "../Pages/Dashboard/DashboardHome/Summary/SumaryDash";
import ListDomains from "../Pages/Dashboard/DashboardHome/Summary/ListDomains";
import DetailDomains from "../Pages/Dashboard/DashboardHome/Summary/DetailDomains";



const routes = [
// YANG DIGUNAKAN 
  { path: `${process.env.PUBLIC_URL}/app/insert_keyword/app/insert_keyword`, Component: <SearchInput />},
  { path: `${process.env.PUBLIC_URL}/dashboard/app`, Component: <DashboardHome /> },
  { path: `${process.env.PUBLIC_URL}/dashboard/app/list-domain`, Component: <ListDomains /> },
  { path: `${process.env.PUBLIC_URL}/dashboard/app/list-domain/detail/:domainId`, Component: <DetailDomains /> },
]

export default routes;