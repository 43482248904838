import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, CardBody, CardText, Spinner, Button, Collapse } from 'reactstrap';

import ENV from "./../../../../env";
const API_URL = ENV.API_URL;

interface Summary {
  keyword_id: number;
  keyword_name: string;
  sentiment_value: string;
  sentiment_category: string;
  kesimpulan: string;
  metode: string;
  created_at: string;
}

const KeywordSummaries: React.FC = () => {
  const [summaries, setSummaries] = useState<Summary[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [showMoreStates, setShowMoreStates] = useState<Record<number, { kesimpulan: boolean; metode: boolean }>>({});
  const clientId = localStorage.getItem('client_id');
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (clientId && token) {
      fetchSummaries(clientId, token);
    }
  }, [clientId, token]);

  const fetchSummaries = async (clientId: string, token: string) => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/keywords/get/keyword-summaries/${clientId}`, {
        headers: { Authorization: `${token}` },
      });
      const data: Summary[] = response.data.data;
      setSummaries(data);
      setShowMoreStates(data.reduce((acc, _, index) => {
        acc[index] = { kesimpulan: false, metode: false };
        return acc;
      }, {} as Record<number, { kesimpulan: boolean; metode: boolean }>));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching summaries:', error);
      setLoading(false);
    }
  };

  const toggleShowMore = (index: number, field: "kesimpulan" | "metode") => {
    setShowMoreStates((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        [field]: !prevState[index][field],
      },
    }));
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col md={12}>
          <h2 className="mb-3 text-center">Hasil Sentimen</h2>
          {loading ? (
            <div className="text-center">
              <Spinner color="primary" />
              <p>Loading summaries...</p>
            </div>
          ) : summaries && summaries.length > 0 ? (
            <Row className="gy-4 justify-content-center">
              {summaries.map((summary, index) => (
                <Col md={10} key={summary.keyword_id}>
                  <Card className="shadow-sm border-secondary">
                    <CardBody>
                      <CardText><strong>Sentimen:</strong> {summary.sentiment_category}</CardText>
                      <CardText><strong>Nilai Sentimen:</strong> {summary.sentiment_value}</CardText>
                      {(['kesimpulan', 'metode'] as Array<'kesimpulan' | 'metode'>).map((field) => (
                        <CardText key={field}>
                          <strong>{field.charAt(0).toUpperCase() + field.slice(1)}:</strong>
                          {!showMoreStates[index]?.[field] ? (
                            <span className="d-block text-truncate" style={{ maxWidth: "80%" }}>
                              {summary[field]}
                            </span>
                          ) : (
                            <span className="d-block">{summary[field]}</span>
                          )}
                          <Button
                            color="link"
                            size="sm"
                            className="p-0 mt-2 d-block text-primary"
                            onClick={() => toggleShowMore(index, field)}
                          >
                            {showMoreStates[index]?.[field] ? 'Show Less' : 'Show More'}
                          </Button>
                        </CardText>
                      ))}
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <p className="text-center">No summaries available for the client.</p>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default KeywordSummaries;
