import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Card, CardBody, Spinner, Alert } from 'reactstrap';

const API_URL = "http://127.0.0.1:5000";

interface DomainDetails {
  child_keywords_id: number;
  created_at: string;
  domain_created_at: string;
  domain_id: number;
  domain_name: string;
  failure_reason: string | null;
  html_elements: string;
  icon_url: string | null;
  kesimpulan: string;
  konten_terkait: string;
  keyword_id: number;
  metode: string;
  publication_date: string | null;
  scraping_status: string;
  script_type: string;
  sentiment_category: string;
  sentiment_value: string;
  summary: string | null;
}

const DomainDetails: React.FC = () => {
  const { domainId } = useParams<{ domainId: string }>();
  const [details, setDetails] = useState<DomainDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (domainId) {
      fetchDomainDetails(domainId);
    }
  }, [domainId]);

  const fetchDomainDetails = async (domainId: string) => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(`${API_URL}/keywords/domains/details/${domainId}`, {
        headers: { Authorization: token },
      });
      const fetchedDetails = response.data.data[0];
      setDetails(fetchedDetails);
    } catch (error) {
      setError("Failed to fetch domain details. Please try again later.");
      console.error('Error fetching domain details:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-4">
      {loading && (
        <div className="text-center">
          <Spinner color="primary" />
          <p>Loading domain details...</p>
        </div>
      )}
      {!loading && error && (
        <Alert color="danger" className="text-center">
          {error}
        </Alert>
      )}
      {!loading && details && (
        <Row>
          <Col md={8} className="mx-auto">
            <Card className="shadow-sm">
              <CardBody>
                <h1 className="text-center mb-4">{details.domain_name}</h1>
                <Row>
                  <Col sm={6}>
                 
                    <p>
                      <strong>Sentiment:</strong> {details.sentiment_category} ({details.sentiment_value})
                    </p>
                    
                  </Col>
                  <Col sm={6}>
                    {details.icon_url && (
                      <div className="text-center">
                        <img
                          src={details.icon_url}
                          alt="Domain Icon"
                          style={{ maxWidth: '100px', marginBottom: '10px' }}
                        />
                      </div>
                    )}
                   
                  </Col>
                </Row>
                <hr />
                {/* <h3>Summary</h3>
                <p>{details.summary || 'No summary available.'}</p> */}
                <h3>Methodology</h3>
                <p>{details.metode}</p>
                <hr />
                <h3>Kesimpulan</h3>
                <p>{details.kesimpulan}</p>
                <hr />
                <h3>Konten Terkait</h3>
                <p>{details.konten_terkait}</p>
                <hr />
                {/* <h3>HTML Elements</h3>
                <p>{details.html_elements}</p> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {!loading && !error && !details && (
        <p className="text-center">No details available for this domain.</p>
      )}
    </Container>
  );
};

export default DomainDetails;
