import { Link, useNavigate } from "react-router-dom";
import { FeatherIcons, LI, UL } from "../../../../AbstractElements";
import { profilesMessage } from "../../../../Data/LayoutData/HeaderData";

const ProfileBox = () => {
  const navigate = useNavigate();

  const handleClick = (name: string) => {
    if (name === "Log Out") {
      // Daftar kunci yang akan dihapus dari localStorage dan sessionStorage
      const keysToRemove = [
        "rememberMe",
        "session_token",
        "user_id",
        "client_id",
        "login",
        "role",
        "username",
        "whatsapp_number",
        "token",
        "exp",
      ];

      // Hapus item dari localStorage
      keysToRemove.forEach((key) => localStorage.removeItem(key));

      // Hapus item dari sessionStorage
      keysToRemove.forEach((key) => sessionStorage.removeItem(key));

      // Tampilkan notifikasi atau pemberitahuan jika diperlukan
      console.log("Logout successful. Redirecting to login page.");

      // Arahkan ke halaman login
      navigate("/login");
    }
  };

  return (
    <UL className="profile-dropdown onhover-show-div">
      {profilesMessage.map((data, index) => (
        <LI key={index}>
          <Link
            to={data.link}
            onClick={() => handleClick(data.name)}
          >
            <FeatherIcons iconName={data.icon} />
            <span>{data.name}</span>
          </Link>
        </LI>
      ))}
    </UL>
  );
};

export default ProfileBox;
